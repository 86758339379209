.scheduleTable {
    font-size: small;
    .c1 {
        width: 40%;
    }

    .c2 {
        width: 60%;
    }

    .d2 {
        text-align: right;
    }

    .rowCol0 {
        background-color:burlywood;
    }
    .rowCol1 {
        background-color:antiquewhite;
    }
}