#balanceGrid {
    font-size: 8px !important;
}


.balanceBoard {
    .dx-datagrid .dx-row>td {
        font-size: small !important;
    }

    .dx-row {
        height: 35px;
    }
}