.homework-items {
    width: 100%;
    display: flex;
    margin: 2px;
}

.in-time {
    background-color: rgba(138, 169, 197, 0.592);
}

.overdue {
    background-color: rgba(237, 171, 183, 0.592);

}

.solved {
    background-color: rgba(158, 198, 153, 0.592);

}

.homework-status {
    width: 50px;
}

.hw-reply-text {
    margin: 5px;
}

.homework-button {
    margin-top: 10px;
    width: 50px;
    font-size: xx-large;
}

.homework-task {
    width: 65%;
}

.homework-reply {
    width: 30%;
}