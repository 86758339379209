.homework-item {
  width: 98%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  cursor: pointer;
}

.hw-column {
  float: left;

}

.hw-column.main {
  width: 100%
}



.hw-column.file {
  width: 33.33%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 700px) {

  .hw-column.main,
  .hw-column.file {
    width: 100%;
  }
}