@import url("https://fonts.googleapis.com/css?family=McLaren|Lato:300,400,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  background-color: #f4f6f8;
  color: #ffffff;
}

.container {
  padding: 1em;
}
