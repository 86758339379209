.logo {
	text-align: center;
	font-size: 25px;
	font-family: 'McLaren', cursive;
	font-weight: 700;
	margin: 10px 0;
	color: #353535;
}

.quiz_container {
	width: 100%;
	margin: 10px auto 5px;
	position: relative;
	/* border: 1px solid #e4e4e4; */
	padding: 10px 5px;
	border-radius: 2px 2px 25px 25px;
	background-color: #ffffff;
	box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.4);
}

.question {
	font-size: 55px;
	text-align: center;
	font-weight: 300;
	color: #353535;
	margin: 0 0 30px;
}

.answer {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	transition: all 100ms linear;
	box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.6);
}

button:active {
	transform: scale(1.09);
	box-shadow: 0 5px 18px -2px rgba(0, 0, 0, 0.4);
}

.right svg,
.wrong svg {
	stroke: #ffffff;
	transform: scale(1.3);
}

.right {
	background-color: #50cc7f;
}

.wrong {
	background-color: #ce506c;
}

.restart {
	height: 60px;
	width: 60px;
	background-color: #9663e7;
}

.restart svg {
	stroke: #ffffff;
}

.score {
	text-align: center;
	font-size: 30px;
	color: #353535;
	font-weight: 400;
	padding: 20px;
}

.result {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* background-color: #e3daf0; */
	font-size: 32px;
	font-weight: 700;
	color: #353535;
}

.result::after {
	content: '';
	height: 5px;
	width: 100%;
	background-color: #9663e7;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50px;
}

.result h1 {
	font-size: 30px !important;
	font-weight: 400 !important;
	margin: 20px 0 !important;
}

.timer {
	height: 5px;
	width: 100%;
	background-color: #dadada;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50px;
}

.timer .clock {
	height: 100%;
	background-color: #1f87e7;
	border-radius: 50px;
}
