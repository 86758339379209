.ASZF {
    ol.behuzas {
        list-style-position: outside;
    }

    ol.child {
        margin-left: 15px;
    }
}

.darkBlue {
    color: darkblue;
}

.red {
    color: darkred;
}