@import "../../themes/generated/variables.base.scss";

.create-account-form {
  .policy-info {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    font-size: 14px;
    font-style: normal;

    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }

  .aszf-info {
    margin: 10px 0;
    color: darkred;
    font-size: 14px;
    font-style: normal;

    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }


  }

  .aszf-info:hover {
    cursor: help;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }

  .dx-field-item {
    padding-bottom: 2px !important;
    padding-top: 3px !important;
  }

  .dx-field-item-label {
    padding-left: 2px;
    padding-top: 3px;
  }

  .dx-field-item:not(.dx-first-col) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .dx-field-item:not(.dx-last-col) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .dx-texteditor-input {
    padding: 3px !important;
  }

  .dx-lookup-field {
    padding: 3px !important;
  }
}